import resume from '../assets/pdf/resume.pdf'
import propic from '../assets/png/pro.jpg'

export const headerData = {
    name: 'Vimanga',
    title: "Software Engineer",
    desciption:"“Google and Stack Overflow are great sources of opinions and information, but they’re no substitute for actual human experience.”",
    image: propic,
    resumePdf: resume
}
