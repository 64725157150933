export const experienceData = [
    {
        id: 1,
        company: 'Self employed',
        jobtitle: 'Freelance Software engineer',
        startYear: '02/2021',
        endYear: 'Present'
    },
    {
        id: 1,
        company: 'MTECH Software Solutions (PVT) Ltd',
        jobtitle: 'Senior Software engineer',
        startYear: '11/2019',
        endYear: '02/2021'
    },
    {
        id: 2,
        company: 'MTECH Software Solutions (PVT) Ltd',
        jobtitle: 'Software engineer',
        startYear: '11/2017',
        endYear: '11/2019'
    },
    {
        id: 3,
        company: 'MTECH Software Solutions (PVT) Ltd',
        jobtitle: 'Associate Software engineer',
        startYear: '11/2016',
        endYear: '11/2017'
    },
    {
        id: 4,
        company: '230 interactive',
        jobtitle: 'Web developer',
        startYear: '05/2016',
        endYear: '11/2016'
    },
]