export const socialsData = {
    // github: 'https://github.com/safebinarybot',
    facebook: 'https://www.facebook.com/vimanga.pramodh/',
    linkedIn: 'https://lk.linkedin.com/in/vimanga-panawennage-403623b4',
    // instagram: 'https://www.instagram.com/',
    // codepen: 'https://codepen.io/',
    // twitter: 'https://twitter.com/binarybot2',
    // reddit: 'https://www.reddit.com/user/',
    // blogger: 'https://vimanga.carbonmade.com/blog',
    // medium: 'https://medium.com/@',
    // stackOverflow: 'https://stackoverflow.com/users/',

    // youtube: 'https://www.youtube.com/channel/UCKQIRSSW_khlS10Mlob6RLA'
}