export const educationData = [
    {
        id: 3,
        institution: 'Cardiff Metropolitan University',
        course: 'Reading - Master of Science in Information Technology',
        startYear: '2022',
        endYear: 'Present'
    },
    {
        id: 2,
        institution: 'Java Institute (Chamber of chartered java professionals international)',
        course: 'Bsc in software engineering',
        startYear: '2011',
        endYear: '2016'
    },
    {
        id: 1,
        institution: 'Mahanama College Colombo - 03',
        course: 'GCE Advanced Level [Commerce Stream A/A/C]',
        startYear: '2001',
        endYear: '2014'
    },
]