export const blogData = [
    {
        id: 1,
        title: 'Sniper Bot Guide to Getting Started : Binary.com | Deriv.com',
        description: 'Sniper bot is introduced for the traders to have clearly structured approach for their trading. ',
        date: 'May 22, 2022',
        image: 'https://lh3.googleusercontent.com/tptQ2aknClj-bYekc_Ze8a9enCfguada1qP9ZyuaT08rfg-CsREla1eCeQtuNc6jcWnKg2w9RNBlN-W71nZyUNKY0LBtkCmhIzSrO-fQhCfakF-4Yg5pxBFb1QdQRSeTrhab5ZsJ',
        url: 'https://vimangaworks.blogspot.com/2022/05/sniper-bot-guide-to-getting-started.html'
    },
    {
        id: 2,
        title: 'Binary.com | Mbot',
        description: 'Mtrader is introduced to give you the hybrid experience of manual trading and automatic trading for the traders. ',
        date: 'May 22, 2022',
        image: 'https://lh4.googleusercontent.com/kxHFLjeMaviYtQA55VfRuBzjKPBi4qUYVfOrsnS6q3tdVczE498tq8xFnlzn428y1greXlcWqoCq83Q18q3nQqsvqVHSYmJytSz3clM5UR8eeDoefUkXGi7Zp_SVow-cgwN9F2dg60E8i11zrQ',
        url: 'https://vimangaworks.blogspot.com/2022/05/binarycom-mbot.html'
    },
    {
        id: 3,
        title: 'Binary Auto Trading Platform',
        description: 'The best binary bot traders can find is the Binarybot.live. It consists of free bots',
        date: 'May 19, 2022',
        image: 'https://lh5.googleusercontent.com/sSz73B9JrbLDPGZb4lIAur89YAfPeVBi0ctqQIshJmdQsuKsp-yYRm5maaA79XaPqbRCfBCR6h3987C-_Jy1-GCmxn8klM4OJDhdSXsK1J--KBT-b91I6UjY2WB1boTRw9lVmJrX',
        url: 'https://vimangaworks.blogspot.com/2022/05/binary-auto-trading-platform.html'
    },
    {
        id: 4,
        title: 'LDP Binary Analyzer for Binary.com | Deriv.com',
        description: 'LDP Analyzer is introduced to the binary traders by the binary bot live for the easy usage and ',
        date: 'May 22, 2022',
        image: 'https://lh5.googleusercontent.com/YCO6hycJHl7wKHN0uRkRXhoQz1GSCtELjNsKDiP1Oyn6a9d0YBV0pZ4eiSTmWZHX90B5KdaBDmNIkuy7N5nOCGkHR5NXITKi_2f11jhC-7PK7bLL060WER7uNPeKsvUfG-EBQpS6sCqRC_xK5g',
        url: 'https://vimangaworks.blogspot.com/2022/05/ldp-binary-analyzer-for-binarycom.html'
    },
    // {
    //     id: 5,
    //     title: 'GET MICHAEL\'S OR TREVOR\'S VEHICLES FULLY UPGRADED IN GTA V FOR FREE',
    //     description: 'In case you haven\'t heard of this game, GTA stands for \'Grand Theft Auto\'. It is an open-world game published by Rockstar Games.',
    //     date: 'Oct 1, 2020',
    //     image: 'https://1.bp.blogspot.com/-jhdtUBIRD_s/XxLFKQuRz9I/AAAAAAAABsM/mClfbfDFKvsUwA7Wyi25Lzu3DEmL2lxrACNcBGAsYHQ/s1919/gta.jpg',
    //     url: 'https://hackzism.blogspot.com/2020/07/how-can-you-get-michaels-or-trevors.html'
    // },
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/